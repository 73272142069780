import React from "react";
import loadable from "@loadable/component";
//antd에서 아이콘 가져오기
import {
  UserOutlined,
  SettingOutlined,
  DollarCircleOutlined,
  BarChartOutlined,
  ReadOutlined,
  ShopOutlined,
  BarcodeOutlined,
  BellOutlined,
  TeamOutlined,
  InboxOutlined,
  SnippetsOutlined,
  GiftOutlined,
  FormOutlined
} from "@ant-design/icons";
//회원 전용 루트
import { PUBLIC_ROUTE } from "./routes.constants";
import { Redirect } from "react-router";

//로그인 없이 이용가능 페이지
export const publicRoutes = [
  //첫시작 로그인으로 설정 => ('/' 해당 url 사용 안함)
  {
    exact: true,
    path: PUBLIC_ROUTE.ROOT,
    component: () => {
      if (localStorage.getItem("token")) {
        return <Redirect to="/dashboard" />
      } else {
        return <Redirect to="/login" />
      }
    },
  },
  {
    exact: true,
    path: PUBLIC_ROUTE.LOGIN,
    component: loadable(() => import("../pages/auth/Login")),
  },
];

//로그인 시에만 이용가능 페이지
export const privateRoutes = [
  {
    exact: true,
    path: "/dashboard",
    sidebar: {
      icon: <BarChartOutlined />,
      label: "대시보드 관리",
    },
    component: loadable(() => import("../pages/dashBoard/List")),
  },
  {
    exact: true,
    path: "/user",
    sidebar: {
      icon: <UserOutlined />,
      label: "회원 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "회원 목록",
        },
        component: loadable(() => import("../pages/user/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/user/Detail")),
      },
      {
        exact: true,
        path: "/withdrawal",
        sidebar: {
          label: "탈퇴 회원 목록",
        },
        component: loadable(() => import("../pages/user/WithdrawalList")),
      },
      {
        exact: true,
        visible: false,
        path: "/withdrawal/detail/:id",
        component: loadable(() => import("../pages/user/WithdrawalDetail")),
      },
      {
        exact: true,
        path: "/baby",
        sidebar: {
          label: "BABY 목록",
        },
        component: loadable(() => import("../pages/user/BabyList")),
      },
    ],
  },
  {
    exact: true,
    path: "/product",
    sidebar: {
      icon: <ShopOutlined />,
      label: "상품 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "상품 목록",
        },
        component: loadable(() => import("../pages/product/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/modify/:id",
        component: loadable(() => import("../pages/product/Modify")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/product/Register")),
      },
      {
        exact: true,
        path: "/delivery",
        sidebar: {
          label: "배송비 설정",
        },
        component: loadable(() => import("../pages/product/DeliveryPrice")),
      },
    ],
  },
  {
    exact: true,
    path: "/order",
    sidebar: {
      icon: <SnippetsOutlined />,
      label: "주문 관리",
    },
    children: [
      {
        exact: true,
        path: "/pay/complete",
        sidebar: {
          label: "결제 완료",
        },
        component: loadable(() => import("../pages/order/List")),
      },
      {
        exact: true,
        path: "/delivery/ready",
        sidebar: {
          label: "배송 준비중",
        },
        component: loadable(() => import("../pages/order/List")),
      },
      {
        exact: true,
        path: "/delivery",
        sidebar: {
          label: "배송중",
        },
        component: loadable(() => import("../pages/order/List")),
      },
      {
        exact: true,
        path: "/delivery/complete",
        sidebar: {
          label: "배송 완료",
        },
        component: loadable(() => import("../pages/order/List")),
      },
      {
        exact: true,
        path: "/change/request",
        sidebar: {
          label: "교환 신청",
        },
        component: loadable(() => import("../pages/order/List")),
      },
      {
        exact: true,
        path: "/change/reDelivery/ready",
        sidebar: {
          label: "교환 반송 대기",
        },
        component: loadable(() => import("../pages/order/List")),
      },
      {
        exact: true,
        path: "/change/reDelivery",
        sidebar: {
          label: "교환 반송중",
        },
        component: loadable(() => import("../pages/order/List")),
      },
      {
        exact: true,
        path: "/change/reDelivery/complete",
        sidebar: {
          label: "교환 반송 완료",
        },
        component: loadable(() => import("../pages/order/List")),
      },
      {
        exact: true,
        path: "/change/delivery",
        sidebar: {
          label: "교환 배송중",
        },
        component: loadable(() => import("../pages/order/List")),
      },
      {
        exact: true,
        path: "/change/complete",
        sidebar: {
          label: "교환 완료",
        },
        component: loadable(() => import("../pages/order/List")),
      },
      {
        exact: true,
        path: "/refund/request",
        sidebar: {
          label: "환불 신청",
        },
        component: loadable(() => import("../pages/order/List")),
      },
      {
        exact: true,
        path: "/refund/reDelivery/ready",
        sidebar: {
          label: "환불 반송 대기",
        },
        component: loadable(() => import("../pages/order/List")),
      },
      {
        exact: true,
        path: "/refund/reDelivery",
        sidebar: {
          label: "환불 반송중",
        },
        component: loadable(() => import("../pages/order/List")),
      },
      {
        exact: true,
        path: "/refund/reDelivery/complete",
        sidebar: {
          label: "환불 반송 완료",
        },
        component: loadable(() => import("../pages/order/List")),
      },
      {
        exact: true,
        path: "/refund/complete",
        sidebar: {
          label: "환불 완료",
        },
        component: loadable(() => import("../pages/order/List")),
      },
      {
        exact: true,
        path: "/pay/cancel",
        sidebar: {
          label: "결제 취소 신청",
        },
        component: loadable(() => import("../pages/order/List")),
      },
      {
        exact: true,
        path: "/pay/cancel/complete",
        sidebar: {
          label: "결제 취소 완료",
        },
        component: loadable(() => import("../pages/order/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/order/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/reDelivery",
    sidebar: {
      icon: <GiftOutlined />,
      label: "재배송 관리",
    },
    children: [
      {
        exact: true,
        path: "/ready",
        sidebar: {
          label: "재배송 준비중",
        },
        component: loadable(() => import("../pages/reDelivery/List")),
      },
      {
        exact: true,
        path: "/progress",
        sidebar: {
          label: "재배송중",
        },
        component: loadable(() => import("../pages/reDelivery/List")),
      },
      {
        exact: true,
        path: "/complete",
        sidebar: {
          label: "재배송 완료",
        },
        component: loadable(() => import("../pages/reDelivery/List")),
      },
    ],
  },
  {
    exact: true,
    path: "/request",
    sidebar: {
      icon: <InboxOutlined />,
      label: "검사 신청 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "검사 신청 목록",
        },
        component: loadable(() => import("../pages/request/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/request/Detail")),
      },
      {
        exact: true,
        path: "/before",
        sidebar: {
          label: "반송 신청 목록 (반송 등록전)",
        },
        component: loadable(() => import("../pages/request/ReturnList")),
      },
      {
        exact: true,
        path: "/complete",
        sidebar: {
          label: "반송 신청 목록 (반송 등록 완료)",
        },
        component: loadable(() => import("../pages/request/ReturnList")),
      },
    ],
  },
  {
    exact: true,
    path: "/dna",
    sidebar: {
      icon: <ReadOutlined />,
      label: "검사 항목 데이터 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "검사 항목 목록",
        },
        component: loadable(() => import("../pages/dna/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/dna/Detail")),
      },
      {
        exact: true,
        path: "/category",
        sidebar: {
          label: "케미 카테고리 목록",
        },
        component: loadable(() => import("../pages/dna/category/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/category/detail/:id",
        component: loadable(() => import("../pages/dna/category/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/survey",
    sidebar: {
      icon: <FormOutlined />,
      label: "설문 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "기본 설문 결과 목록",
        },
        component: loadable(() => import("../pages/survey/List")),
      },
      {
        exact: true,
        path: "/adult",
        sidebar: {
          label: "이벤트 설문 (성인) 결과 목록",
        },
        component: loadable(() => import("../pages/survey/AdultList")),
      },
      {
        exact: true,
        path: "/baby",
        sidebar: {
          label: "이벤트 설문 (어린이) 결과 목록",
        },
        component: loadable(() => import("../pages/survey/BabyList")),
      },
    ],
  },
  {
    exact: true,
    path: "/site",
    sidebar: {
      icon: <SettingOutlined />,
      label: "사이트 관리",
    },
    children: [
      {
        exact: true,
        path: "/term",
        sidebar: {
          label: "약관 및 이용안내 설정",
        },
        component: loadable(() => import("../pages/site/term/List")),
      },
      {
        exact: true,
        path: "/introduce",
        sidebar: {
          label: "상품 간단 소개 영상 설정",
        },
        component: loadable(() => import("../pages/site/introduce/List")),
      },
      {
        exact: true,
        path: "/youtube",
        sidebar: {
          label: "소개 유튜브 설정",
        },
        component: loadable(() => import("../pages/site/youtube/List")),
      },
      {
        exact: true,
        path: "/faq",
        sidebar: {
          label: "FAQ 관리",
        },
        component: loadable(() => import("../pages/site/faq/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/faq/modify/:id",
        component: loadable(() => import("../pages/site/faq/Modify")),
      },
      {
        exact: true,
        visible: false,
        path: "/faq/register",
        component: loadable(() => import("../pages/site/faq/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/faq/category",
        component: loadable(() => import("../pages/site/faq/category/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/faq/category/register",
        component: loadable(() => import("../pages/site/faq/category/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/faq/category/modify/:id",
        component: loadable(() => import("../pages/site/faq/category/Modify")),
      },
    ],
  },
  {
    exact: true,
    path: "/barcode",
    sidebar: {
      icon: <BarcodeOutlined />,
      label: "바코드 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "바코드 리스트",
        },
        component: loadable(() => import("../pages/barcode/List")),
      },
    ],
  },
];
